//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import Swiper from 'swiper';

import { listChannelCategories, listMediaTypeTerms, listCurations } from '@/api/channels.js';

export default {
  name: 'ChannelMediaTypeCategories',
  props: {
    heading: String,
    channelId: String,
    mediaType: String,
    type: String,
    titleOnly: {
      type: Boolean,
      default: false,
    },
    hideShowAll: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ChannelsCategoriesListItem: () => import('./ChannelsCategoriesListItem'),
    ProductCardLoader: () => import('@motionelements/core/src/components/product-card/ProductCardLoader.vue'),
  },
  data() {
    return {
      swiper: null,
      isLoading: false,
      categories: [],
      showAll: false,
      defaultSwiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 16,
        loop: false,
        autoplay: false,
        centerInsufficientSlides: true,
        watchSlidesProgress: true,
        enabled: true,
        breakpoints: {
          768: {
            enabled: false,
          },
        },
      },
    };
  },
  mounted() {
    this.listMediaTypeCategories();
  },
  beforeDestroy() {
    if (this.swiper && this.swiper.el) {
      this.swiper.destroy();
    }
  },
  computed: {
    ...mapState({
      windowWidth: state => state.browser.window.width,
    }),
    isProduction() {
      return process.env.VUE_APP_ENV === 'production';
    },
    categoriesListItemsCount() {
      const width = this.windowWidth;

      switch (true) {
        case width >= 1600:
          return 10;
        case width >= 992:
          return 8;
        case width >= 768:
          return 6;
        default:
          return 6;
      }
    },
    categoriesFiltered() {
      return this.isProduction && !this.titleOnly && this.type !== 'curations'
        ? this.categories.filter(category => this._.get(category, 'thumbnails[0].url'))
        : this.categories;
    },
    categoriesList() {
      if (this.showAll || this.hideShowAll) {
        return this.categoriesFiltered;
      }
      return this.categoriesFiltered.slice(0, this.categoriesListItemsCount);
    },
  },
  methods: {
    initSwiper(swiperOptions = {}) {
      this.swiper = new Swiper(this.$refs.categoriesSwiper, {
        ...this.defaultSwiperOptions,
        ...swiperOptions,
      });
    },
    updateSwiper() {
      if (this.swiper) {
        this.swiper.updateSlides();
      }
    },
    listMediaTypeCategories() {
      this.isLoading = true;
      if (this.type === 'curations') {
        listCurations('music-channel', { page: 1, per_page: 10, 'fields[page]': 'heading,background_image,html' })
          .then(({ data: { data: curations } }) => {
            this.categories = curations ? curations : [];
          })
          .catch(err => console.error(err))
          .finally(() => {
            this.isLoading = false;
            this.$nextTick(() => {
              this.initSwiper({ centerInsufficientSlides: false });
            });
          });
      } else if (this.type === 'instrument') {
        listMediaTypeTerms(this.channelId)
          .then((response) => {
            this.categories = response.data.data.instruments;
          })
          .catch((e) => {
            console.warn(e);
          })
          .finally(() => {
            this.isLoading = false;
            this.$nextTick(() => {
              this.initSwiper();
            });
          });
      } else {
        listChannelCategories(this.channelId, {
          media_type: this.mediaType,
          type: this.type,
        })
          .then((response) => {
            this.categories = response.data.data;
          })
          .catch((e) => {
            console.warn(e);
          })
          .finally(() => {
            this.isLoading = false;
            this.$nextTick(() => {
              this.initSwiper();
            });
          });
      }
    },
    showAllCategories() {
      this.showAll = true;
      this.$nextTick(() => {
        this.updateSwiper();
      });
    },
    categoryLink(category) {
      if (this.type === 'curations') {
        return category.html.url;
      }
      return this.$link(`/search/${this.mediaType.replace('_', '-')}`).param(`${this.type}`, category.id).url();
    },
  },
};
