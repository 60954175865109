//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ChannelsCategoriesListItem',
  components: {
    BaseImg: () => import('@motionelements/core/src/components/ui/BaseImg.vue'),
  },
  props: {
    category: Object,
    link: String,
    titleOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    categoryImg() {
      return this._.get(this.category, 'thumbnails[0].url') || _.get(this.category, 'backgroundImage.url');
    },
  },
};
